
import * as PIXI from 'pixi.js';
import SpriteCommon from "../common/SpriteCommon";
import ResourceList from "../../../services/ResourceList";
import { Container } from "pixi.js";

class MagnetEffect extends PIXI.Container {
    constructor(){
        super()
        this.wave1 = new SpriteCommon(ResourceList.MSC_MAGNET_EFFECT);
        this.wave2 = new SpriteCommon(ResourceList.MSC_MAGNET_EFFECT);
        this.wave3 = new SpriteCommon(ResourceList.MSC_MAGNET_EFFECT);

        this.wave1.anchor.set(0.5, 0.5);
        this.wave2.anchor.set(0.5, 0.5);
        this.wave3.anchor.set(0.5, 0.5);

        this.addChild(this.wave1);
        this.addChild(this.wave2);
        this.addChild(this.wave3);
    }

    update(t) {
        const ph = t / 10;

      const c1 = ph % (Math.PI / 2)
      const c2 = (ph + Math.PI / 3) % (Math.PI / 2)
      const c3 = (ph + 2 * Math.PI / 3) % (Math.PI / 2)

      this.wave1.alpha = 1 - Math.abs(Math.cos(c1));
      this.wave2.alpha = 1 - Math.abs(Math.cos(c2));
      this.wave3.alpha = 1 - Math.abs(Math.cos(c3));

      const sc1 = Math.abs(Math.cos(c1)) * 2;
      const sc2 = Math.abs(Math.cos(c2)) * 2;
      const sc3 = Math.abs(Math.cos(c3)) * 2;

        this.wave1.scale.set(sc1);
        this.wave2.scale.set(sc2);
        this.wave3.scale.set(sc3);

    }


}

export default MagnetEffect
