import gsap from 'gsap'
import { Utils } from '../../utils/Utils'
import * as PIXI from 'pixi.js';
import SingleParticle from './SingleParticle'

export default class ParticleEmitter extends PIXI.Container {
  _particles = new PIXI.Container()
  _pool = new PIXI.Container()
  _emittersData = {}
  _emitters = {}
  _emitterAlive = false
  _emitterTimers = {}
  _timeScale = 1

  constructor() {
    super()
    this.addChild(this._particles)

    let time = 0
    let deltaTime
    gsap.ticker.add((elapsed) => {
      deltaTime = elapsed - time
      time = elapsed
      this.timeScale && this.update(deltaTime * this.timeScale)
    })
  }

  start(x, y, num = 10, name) {
    const names = Object.keys(this._emitters)
    if (!names.length) {
      console.log('PARTICLE CONFIG REQUIRED')
      return
    }

    name = name ?? names[0]

    if (!this._emittersData[name].origin) {
      this._emittersData[name].origin = new PIXI.Point(x, y);
    } else {
      this._emittersData[name].origin.set(x, y)
    }

    for (let i = 0; i < num; i++) {
      const particle = this.getFromPool()
      this._particles.addChild(particle)
      let particleData;
      if (this._emittersData[name].origin) {
        const {x, y} = this._emittersData[name].origin
        particleData = this._emitters[name](x, y)
      }
      else {
        particleData = this._emitters[name](x, y)
      }
      particle.setData(particleData)
      particle.initParticle(name + i)
    }

    this._emitterAlive = true

    const { birthrate = 0 } = this._emitters[name]()

    if (birthrate > 0) {
      !this._emitterTimers[name] && (this._emitterTimers[name] =   [])

        const t1 = gsap.to(this, {
        duration: birthrate,
        onComplete: () => {
          const {x, y} = this._emittersData[name].origin
          this.start(x, y, num, name)
        },
      });

      this._emitterTimers[name].push(t1)
      t1.timeScale = this._timeScale
    }
  }

  stop(name) {
    this._emitterTimers[name]?.forEach((timer) => {
      timer?.kill()
    });
  }

  stopAll() {
    Object.values(this._emitterTimers).forEach((timers) => {
      timers.forEach((timer) => {
        timer?.kill()
      });
    })
  }

  set timeScale(value) {
    this._timeScale = value
    for (let key in this._emitterTimers) {
      this._emitterTimers[key].forEach((timer) => {
        timer.timeScale = this._timeScale
      })
    }
  }

  get timeScale() {
    return this._timeScale
  }

  add(name, data = {}) {
    this._emittersData[name] = data

    this._emitters[name] = (x = 0, y = 0) => {
      const data = this._emittersData[name]
      const texture = Array.isArray(data.textures) ? Utils.pick(data.textures) : data.textures ?? null

      return {
        origin: data.origin,
        x: data.origin.x + Utils.random(-data.width / 2, data.width / 2),
        y: data.origin.y + Utils.random(-data.height / 2, data.height / 2),
        width: data.width ?? 0,
        height: data.height ?? 0,
        color: Array.isArray(data.colors) ? Utils.pick(data.colors) : data.colors ?? 0xffffff,
        colorOverLife: Array.isArray(data.colorOverLife) ? data.colorOverLife : null,
        texture: Array.isArray(texture) ? null : texture,
        textureOverLife: Array.isArray(texture) ? texture : null,
        animatedTextureFPS: data.animatedTextureFPS ?? 15,
        animatedTextureRandom: data.animatedTextureRandom ?? 0,
        life: Array.isArray(data.life) ? Utils.random(...data.life) : data.life ?? 1,
        vector: new PIXI.Point(Utils.random(-1, 1), Utils.random(-1, 1)).normalize().add(data.vector?.clone() ?? new PIXI.Point()),
        velocity: Array.isArray(data.velocity) ? Utils.random(...data.velocity) : data.velocity ?? 0,
        velocityOverLife: Array.isArray(data.velocityOverLife) ? data.velocityOverLife : null,
        alpha: Array.isArray(data.alpha) ? Utils.random(...data.alpha) : data.alpha ?? 1,
        alphaOverLife: Array.isArray(data.alphaOverLife) ? data.alphaOverLife : null,
        scale: Array.isArray(data.scale) ? Utils.random(...data.scale) : data.scale ?? 1,
        scaleOverLife: Array.isArray(data.scaleOverLife) ? data.scaleOverLife : null,
        oriented: data.oriented ?? false,
        rotation: Array.isArray(data.rotation) ? Utils.random(...data.rotation) : data.rotation ?? 0,
        rotationVelocity: Array.isArray(data.rotationVelocity) ? Utils.random(...data.rotationVelocity) : data.rotationVelocity ?? 0,
        birthrate: data.birthrate ?? 0,
        forceVectors: data.forceVectors ?? [],
        force: data.force ?? 0,
        forceOverLife: Array.isArray(data.forceOverLife) ? data.forceOverLife : null,
        twirlForce: data.twirlForce ?? 0,
        twirlForceOverLife: Array.isArray(data.twirlForceOverLife) ?? null,
      }
    }
    return this._emittersData[name]
  }

  getFromPool() {
    let particle = this._pool.children[0]
    if (!particle) {
      particle = new SingleParticle(this)
    }

    return particle
  }

  removeParticle(particle) {
    this._particles.removeChild(particle)
    this._pool.addChild(particle)

    if (!this._particles.children.length) {
      this._particles.removeChildren()
      this._emitterAlive = false
    }
  }

  update(dt) {
    if (!this._emitterAlive) {
      return
    }
    this._particles.children.forEach((el) => {
      el.alive && el.update(dt, this.timeScale)
    })
  }
}
