const GAME_CONFIG = {
    speeds: {
        '30': {speed: 0.5},
        '60': {speed: 0.4},
        '90': {speed: 0.3},
        '120': {speed: 0.25},
        '150': {speed: 0.2},
        'infinity': {speed: 0.15},
    },
    itemsInRow: {
        '60': {row0: 50, row1: 30, row2: 20, row3: 0},
        '120': {row0: 50, row1: 30, row2: 20, row3: 0},
        '180': {row0: 35, row1: 35, row2: 25, row3: 5},
        '240': {row0: 35, row1: 35, row2: 25, row3: 5},
        '300': {row0: 20, row1: 40, row2: 30, row3: 10},
        '330': {row0: 20, row1: 40, row2: 30, row3: 10},
        '360': {row0: 15, row1: 35, row2: 35, row3: 15},
        '390': {row0: 15, row1: 35, row2: 35, row3: 15},
        '420': {row0: 10, row1: 30, row2: 40, row3: 20},
        '450': {row0: 10, row1: 30, row2: 40, row3: 20},
        '480': {row0: 5, row1: 25, row2: 45, row3: 25},
        '510': {row0: 5, row1: 25, row2: 45, row3: 25},
        '540': {row0: 0, row1: 20, row2: 50, row3: 30},
        '570': {row0: 0, row1: 20, row2: 50, row3: 30},
        '600': {row0: 0, row1: 10, row2: 55, row3: 35},
        'infinity': {row0: 0, row1: 10, row2: 55, row3: 35}
    },
    itemKinds: {
        '60': {plus10: 0.23, plus20: 0.2, minusNpoints: 0.15, minusNseconds: 0.2, plusNseconds: 0.12, magnet: 0.05, speedUp: 0.05},
        '120': {plus10: 0.21, plus20: 0.18, minusNpoints: 0.19, minusNseconds: 0.24, plusNseconds: 0.09, magnet: 0.05, speedUp: 0.04},
        '180': {plus10: 0.19, plus20: 0.16, minusNpoints: 0.22, minusNseconds: 0.28, plusNseconds: 0.07, magnet: 0.05, speedUp: 0.03},
        'infinity': {plus10: 0.17, plus20: 0.14, minusNpoints: 0.26, minusNseconds: 0.31, plusNseconds: 0.05, magnet: 0.05, speedUp: 0.02}
    },
    steakBonuses: {
        '10': {multiplier: 'x1'},
        '20': {multiplier: 'x1.1'},
        '30': {multiplier: 'x1.2'},
        '40': {multiplier: 'x1.3'},
        '50': {multiplier: 'x1.4'},
        '60': {multiplier: 'x1.6'},
        '70': {multiplier: 'x1.8'},
        '80': {multiplier: 'x2'},
        '90': {multiplier: 'x2.5'},
        '150': {multiplier: 'x3'},
        '200': {multiplier: 'x3.5'},
        '300': {multiplier: 'x4'},
        'infinity': {multiplier: 'x5'}
    }

};


export default GAME_CONFIG;